import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";
import languageStore, { LanguageStore } from "./language";
import {
  DocumentId,
  TranslationInfo,
  Document,
  DocumentProto,
  DocumentRelationTypeId,
  DocPayloadForUpload,
  DocPayloadForEdit,
  DocVersion,
  DocumentSearch,
  DocItemType,
} from "~/document";
import SearchQuery from "~/searchQuery";
import notifStore, { NotificationStore } from "~/stores/notifications";
import moment from "moment";
import officeStore from "~/stores/offices";
import ownerStore from "~/stores/owners";
import divisionStore from "~/stores/divisions";
import docSeriesStore from "~/stores/docSeries";
import docTypeStore from "~/stores/documentTypes";
import PaginatedEndpoint, { PaginatedResult } from "~/util/PaginatedEndpoint";
import countryStore from "./country";
import { UserPermission } from "~/user";
import authStore, { AuthStore } from "~/stores/auth";
import { ReqStatus } from "~/util";
import langVersionStore, { DocumentLangVersionStore } from "~/stores/langVersionStore";
import bookmarkStore, { BookmarkStore } from "./bookmarks";

const LATEST_DOCUMENTS_AMOUNT_TO_LOAD = 10;
const LS_LAST_PERFORMED_SEARCH_QUERY = "last-performed-query";

export type { DocumentId };

const INITIAL_SEARCH_RESULT = {
  count: 0,
  offset: 0,
  page_size: 1,
  results: [],
  current_page: 0,
};

export class DocumentStore {
  loadedDocuments: Record<DocumentProto, Document> = {};
  searchResult: { count: number; offset: number; page_size: number; current_page: number; results: Document[] } =
    INITIAL_SEARCH_RESULT;
  currentDocument: Document | null = null;
  searching = false;
  languageStore: LanguageStore;
  docReqStatus: ReqStatus = ReqStatus.Initial;
  currentDocumentTranslations: TranslationInfo[] = [];
  notifStore: NotificationStore;
  documentDeletionStatus: api.ReqStatus = api.ReqStatus.Initial;
  f: PaginatedEndpoint<DocumentSearch>;
  loadedDetails: Set<DocumentProto> = new Set();
  latestDocsReqStatus: ReqStatus = ReqStatus.Initial;

  // Last performed search query
  get prevQuery(): SearchQuery | null {
    const j = localStorage.getItem(LS_LAST_PERFORMED_SEARCH_QUERY) ?? null;
    if (j === null) return null;
    return new SearchQuery(JSON.parse(j));
  }

  setLastQuery(query: SearchQuery) {
    localStorage.setItem(LS_LAST_PERFORMED_SEARCH_QUERY, JSON.stringify(query));
  }

  latestDocumentsIds: DocumentProto[] = [];

  authStore: AuthStore;
  langVersionStore: DocumentLangVersionStore;
  bookmarkStore: BookmarkStore;

  uploadStatus: ReqStatus = ReqStatus.Initial;
  editStatus: ReqStatus = ReqStatus.Initial;
  addRelatedDocStatus: ReqStatus = ReqStatus.Initial;

  get loadingDocumentView() {
    return this.loadingDocument;
  }

  get loadingDocumentViewInfo() {
    return this.loadingDocumentView || bookmarkStore.loading || languageStore.loading;
  }

  clearSearch() {
    this.searchResult = INITIAL_SEARCH_RESULT;
  }

  constructor(
    languageStore: LanguageStore,
    notifStore: NotificationStore,
    authStore: AuthStore,
    langVersionStore: DocumentLangVersionStore,
    bookmarkStore: BookmarkStore
  ) {
    makeAutoObservable(this);
    this.languageStore = languageStore;
    this.notifStore = notifStore;
    this.authStore = authStore;
    this.langVersionStore = langVersionStore;
    this.bookmarkStore = bookmarkStore;
    this.f = new PaginatedEndpoint("search-restful", notifStore, { byId: "dbid" });
  }

  loadIds = async (ids: Document["id"][]): Promise<Document[]> => {
    const params: any = {};
    if (ids.length === 1) {
      params["id"] = ids[0];
    } else if (ids.length > 1) {
      params["id__inlist"] = ids;
    }
    const resp = await api.get("documents", params);
    if (resp === null) return [];
    const data: PaginatedResult<Document> = resp.data;
    const items: Document[] = data.results;
    runInAction(() => {
      for (const item of items) {
        this.loadedDocuments[item.protocol] = item;
      }
    });
    return items;
  };

  get loadingDocument(): boolean {
    return this.docReqStatus === ReqStatus.InProcess;
  }

  async loadDocument(docID: DocumentProto, reload: boolean = false): Promise<Document | null> {
    if (!reload && this.loadedDetails.has(docID)) {
      return this.loadedDocuments[docID];
    }
    languageStore.loadLanguages();
    // fetch the document
    this.docReqStatus = ReqStatus.InProcess;
    try {
      const res = await api.get(`documents/${docID}`, {
        file_preview: 1,
      });
      const doc: Document = res?.data;
      const e = (v: any) => v !== undefined && v !== null;
      if (e(doc.office)) {
        officeStore.f.items[doc.office] = { name: doc.office_display!, id: doc.office };
      }
      if (e(doc.owner)) {
        ownerStore.f.items[doc.owner] = { username: doc.owner_display!, id: doc.owner };
      }
      if (e(doc.division)) {
        divisionStore.f.items[doc.division] = { name: doc.division_display!, id: doc.division };
      }
      if (e(doc.serie)) {
        docSeriesStore.f.items[doc.serie] = { label: doc.serie_display!, id: doc.serie };
      }
      if (e(doc.type)) {
        docTypeStore.docTypesById.set(doc.type, { label: doc.type_display!, id: doc.type });
      }
      if (e(doc.secondary_type)) {
        docTypeStore.docTypesById.set(doc.secondary_type, {
          label: doc.secondary_type_display!,
          id: doc.secondary_type,
        });
      }
      if (e(doc.countries) && doc.countries.length !== 0) {
        const split = doc.countries_display.split(", ");
        for (let i = 0; i < doc.countries.length; ++i) {
          let country = doc.countries[i];
          let countryLabel = split[i];
          countryStore.f.items[country] = { id: country, name: countryLabel };
        }
      }

      this.loadedDocuments[docID] = doc;

      // load bookmarks
      await bookmarkStore.loadFor(doc.id);

      this.loadedDetails.add(docID);

      this.docReqStatus = ReqStatus.Success;

      return doc;
    } catch (err) {
      this.docReqStatus = ReqStatus.Failed;
      throw err;
    }
  }

  async selectDocument(docID: DocumentProto) {
    // load translations for the current document
    runInAction(async () => {
      this.currentDocumentTranslations = await this.langVersionStore.versionsFor(docID);
    });
    this.currentDocument = await this.loadDocument(docID, true);
  }

  async search(query: SearchQuery) {
    this.setLastQuery(query);
    this.searching = true;
    const queryParams = query.toAPIFormat();
    queryParams.append("only_ebmd", "true");
    queryParams.append("with_attachments", "true");
    if (query.page) {
      queryParams.append("page", query.page.toString());
      queryParams.append("page_size", (query.pageSize || 25).toString());
    }
    try {
      const resp = await api.get(`search-restful/?${queryParams.toString()}`);
      if (resp === null) return;
      const data = resp.data;
      this.searchResult = data;
      this.addLoadedDocuments(this.searchResult.results);
      this.searching = false;
      return data;
    } catch (err: any) {
      this.notifStore.error(`Search failed: ${err.toString()}`);
      this.searching = false;
    }
  }

  addLoadedDocuments(documents: Document[]) {
    for (let doc of documents) {
      this.loadedDocuments[doc.protocol] = doc;
    }
  }

  loadLatestDocuments = async (
    search?: string,
    cursor?: string,
    sortBy?: string
  ): Promise<PaginatedResult<DocItemType>> => {
    this.setLastQuery(new SearchQuery());
    this.latestDocsReqStatus = ReqStatus.InProcess;
    try {
      let resp;
      if (cursor) {
        resp = await api.getBare(cursor);
      } else {
        resp = await api.get("ebms:docitems", {
          ...(sortBy ? { ordering: sortBy } : {}),
          eb: "true",
          page_size: LATEST_DOCUMENTS_AMOUNT_TO_LOAD,
        });
      }
      if (resp === null) return;
      const latestDocuments: DocItemType[] = resp.data.results;
      this.latestDocumentsIds = latestDocuments.map((item) => item.protocol);
      this.latestDocsReqStatus = ReqStatus.Success;
      return resp.data;
    } catch (err) {
      this.notifStore.error("Failed to load latest documents");
      this.latestDocsReqStatus = ReqStatus.Failed;
    }
  };

  async uploadDocVersion(doc: Document, file: File, handleFileError?: (error: any) => void) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("document", doc.id.toString());
      const resp = await api.postMultipart(`versions`, formData);
      if (resp === null) return null;
      await api.post(`ebms:docitems/${doc.uuid}/update-dss-data`);
      await this.loadDocument(doc.protocol);
      return resp.data;
    } catch (err: any) {
      if (err?.response?.data?.file?.[0]) {
        handleFileError(err.response.data.file[0]);
      }
      this.notifStore.error(`Failed to link version: ${err}`);
    }
  }

  async edit(
    docProto: DocumentProto,
    doc: DocPayloadForEdit,
    file: File | null,
    handleFileError: (error: any) => void
  ) {
    try {
      runInAction(() => {
        this.editStatus = ReqStatus.InProcess;
      });
      const resp = await api.patch(`ebms:docitems/${docProto}`, doc);
      if (resp === null) return null;
      if (file !== null) {
        const updatedDoc: Document = resp.data;
        await this.uploadDocVersion(updatedDoc, file, handleFileError);
      }
      runInAction(() => {
        this.editStatus = ReqStatus.Success;
      });
      this.notifStore.success(`Updated document`);
      return resp.data;
    } catch (err: any) {
      this.notifStore.error(`Failed to edit document: ${err}`);
      runInAction(() => {
        this.editStatus = ReqStatus.Failed;
      });
      return null;
    }
  }

  async upload(doc: Partial<DocPayloadForUpload>, file: File, handleFileError: (error: any) => void) {
    try {
      runInAction(() => {
        this.uploadStatus = ReqStatus.InProcess;
      });
      const docCreationResp = await api.post("ebms:docitems", doc);
      if (!docCreationResp) return null;
      const createdDoc = docCreationResp.data;
      if (file) {
        await this.uploadDocVersion(createdDoc, file, handleFileError);
      }
      runInAction(() => {
        this.uploadStatus = ReqStatus.Success;
      });
      return createdDoc;
    } catch (err: any) {
      this.notifStore.error(`Failed to upload document: ${err}`);
      runInAction(() => {
        this.uploadStatus = ReqStatus.Failed;
      });
      return null;
    }
  }

  async loadDocumentVersions(id: number): Promise<DocVersion[]> {
    const resp = await api.get(`documents/${id}/versions`);
    if (!resp) return [];
    return resp.data;
  }

  // proto
  getDocByProto(proto: DocumentProto): Document | null {
    const d = this.loadedDocuments[proto];
    return d ?? null;
  }

  async deleteDocument(proto: string) {
    this.documentDeletionStatus = api.ReqStatus.InProcess;
    const resp = await api.del(`ebms:docitems/${proto}`);
    if (resp !== null && resp.status === 204) {
      this.documentDeletionStatus = api.ReqStatus.Success;
    } else {
      this.documentDeletionStatus = api.ReqStatus.Failed;
    }
  }

  loadMyDocuments = async (search: string = "", cursor: string | null): Promise<PaginatedResult<Document> | null> => {
    if (!this.authStore?.userInfo?.user) return null;
    try {
      let resp;
      if (cursor === null) {
        resp = await api.get("documents/user_documents", {
          _page_size: 10,
          ordering: "-publication_date",
          owner: this.authStore.userInfo.user,
        });
      } else {
        resp = await api.getBare(cursor);
      }
      if (resp === null) return null;
      return resp.data;
    } catch (err) {
      return null;
    }
  };

  loadMyLatestUpdatedDocs = async (
    search: string = "",
    cursor: string | null
  ): Promise<PaginatedResult<Document> | null> => {
    if (!this.authStore?.userInfo) return null;
    try {
      let resp;
      if (cursor === null) {
        resp = await api.get("documents/user_documents", {
          _page_size: 10,
          ordering: "-last_modified_date,id",
          last_modified_user: this.authStore.userInfo.user,
        });
      } else {
        resp = await api.getBare(cursor);
      }
      if (resp === null) return null;
      return resp.data;
    } catch (err) {
      return null;
    }
  };

  removeRelatedDoc = async (proto: DocumentProto, docId: number, relatedDocId: number, relationType: number) => {
    try {
      await api.del(`ebms:docitems/${docId}/relations`, { type: relationType, related: relatedDocId });
      await this.loadDocument(proto, true);
    } catch (err) {
      this.notifStore.error(`Failed to remove relation, ${err}`);
    }
  };

  addRelatedDoc = async (doc: Document, relationType: DocumentRelationTypeId, related: DocumentSearch) => {
    try {
      runInAction(() => {
        this.addRelatedDocStatus = ReqStatus.InProcess;
      });
      const res = await api.post(`ebms:docitems/${doc.id}/relations`, { type: relationType, related: related.dbid });
      await this.loadDocument(doc.protocol, true);
      if (!res.data) return null;
      runInAction(() => {
        this.addRelatedDocStatus = ReqStatus.Success;
      });
      return res.data;
    } catch (err) {
      this.notifStore.error(`Failed to add relation, ${err}`);
      runInAction(() => {
        this.addRelatedDocStatus = ReqStatus.Failed;
      });
      return null;
    }
  };

  isExpired(doc: Document | DocumentSearch) {
    if (!doc?.expiration_date) {
      return false;
    }
    return moment(doc.expiration_date).isBefore(new Date());
  }

  canModify(doc: Document | DocumentSearch) {
    const expired = this.isExpired(doc);
    const userInfo = this.authStore.userInfo;
    return !expired || userInfo.is_superuser || +doc.owner === +userInfo.user;
  }

  canDownload(doc: Document | DocumentSearch) {
    const expired = this.isExpired(doc);
    const userInfo = this.authStore.userInfo;
    return doc.file_info?.name !== null && (!expired || userInfo.is_superuser || +doc.owner === +userInfo.user);
  }

  canEdit(doc: Document | DocumentSearch) {
    return this.canModify(doc) && this.authStore.hasPermission(UserPermission.UploadDoc);
  }

  canEditRelations() {
    return this.authStore.hasPermission(UserPermission.AddDocRelation);
  }

  canEditLanguages() {
    return this.authStore.hasPermission(UserPermission.AddDocVersion);
  }

  canDeleteDocument() {
    return this.authStore.hasPermission(UserPermission.DeleteDoc);
  }
}

const documentStore = new DocumentStore(languageStore, notifStore, authStore, langVersionStore, bookmarkStore);

export default documentStore;
