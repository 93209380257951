import notificationStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus } from "~/util";
import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";
import { VenueId } from "~/stores/venues";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import { LinkNode } from "~/stores/links";
import { AgendaItem } from "~/stores/agendaItems";
export type MeetingId = string;

export type MeetingLanguage = "en" | "fr" | "es" | "ar";

export const meetingLanguagesList: { title: string; key: MeetingLanguage }[] = [
  {
    title: "English",
    key: "en",
  },
  {
    title: "French",
    key: "fr",
  },
  {
    title: "Spanish",
    key: "es",
  },
  {
    title: "Arabic",
    key: "ar",
  },
];

export type MeetingsError = {
  error: true;
  fields: { [key: string]: any };
};

export type MeetingStatus = 0 | 100 | 500;
export const meetingStatusMap = {
  0: "Draft",
  100: "Published",
  500: "Archived",
};

export type TimeScheduleItem = {
  id: number;
  key?: string;
  day: string;
  start_time_1: string;
  end_time_1: string;
  start_time_2?: string;
  end_time_2?: string;
  default?: boolean;
};

export type TimeSchedule = TimeScheduleItem[];

export const meetingEventTypes = { "": "", eb: "EB Event", other: "Other" };

export type MeetingEventTypes = string;

export enum MeetingRelevantToEnum {
  First = 1,
  A = "A",
  Second = 2,
  special = "special",
}

export type MeetingRelevantTo = MeetingRelevantToEnum[];

export interface MeetingUploadType {
  meeting_type: string;
  code: string;
  title_en: string;
  start_date: string;
  title_fr?: string;
  title_es?: string;
  title_ar?: string;
  default?: boolean;
  start_time?: string;
  end_date?: string;
  end_time?: string;
  time_schedule: TimeSchedule;
  notes_en?: string;
  notes_fr?: string;
  notes_es?: string;
  notes_ar?: string;
  event_type?: string;
  venue?: VenueId;
  non_working?: boolean;
  interpretation_services?: boolean;
  assistant_executive_director?: string;
  governance_framework?: string;
  relevant_to?: MeetingRelevantTo;
  relationships_notes_en?: string;
  relationships_notes_fr?: string;
  relationships_notes_es?: string;
  relationships_notes_ar?: string;
  internal_notes_en?: string;
  internal_notes_fr?: string;
  internal_notes_es?: string;
  internal_notes_ar?: string;
  president_required?: boolean;
  female_members?: number;
  male_members?: number;
  female_observers?: number;
  male_observers?: number;
  web_traffic_unique_users?: number;
  web_traffic_hits?: number;
}

export interface EBMMAttributes {
  zoom_id: number;
  ebmm_id: number;
  event_status: string;
  event_format: string;
  venue_display: string;
  webcast: boolean;
  webcast_token: string;
  webcast_languages: string[];
}

export type MeetingNode = MeetingUploadType &
  EBMMAttributes & {
    id: MeetingId;
    api_url: string;
    api_clone_url: string;
    status: MeetingStatus;
    links: Partial<LinkNode>[];
    manager: string;
    agenda_items: AgendaItem[];
    has_meetingdocs: string;
    api_unbound_meetingdocs_url: string;
    available_status_choices: string;
    creation_date: string;
    update_date: string;
    publication_date: string;
  };

export type MeetingFormKey = keyof MeetingNode;

export type MeetingCodesSearch = {
  search: string;
  results: string[];
};

export class MeetingStore {
  notifStore: NotificationStore;
  meetings: MeetingNode[];
  meetingsCount: number = 0;
  status: ReqStatus = ReqStatus.Initial;
  codesSearchStatus: ReqStatus = ReqStatus.Initial;
  meetingCodesSearch: MeetingCodesSearch = {
    results: [],
    search: "",
  };
  meetingsQueryParams: { [key: string]: string | number };

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  get loadingCodes(): boolean {
    return this.codesSearchStatus === ReqStatus.InProcess;
  }

  get codesSearchEmpty(): boolean {
    return this.codesSearchStatus === ReqStatus.Initial;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.meetings = [];
    this.notifStore = notifStore;
  }

  clearMeetingCodes = () => {
    runInAction(() => {
      this.codesSearchStatus = ReqStatus.Initial;
      this.meetingCodesSearch = { search: "", results: [] };
    });
  };

  convertMeetingCodesToTypes = async (codes: string[]) => {
    try {
      const resp = await api.post(`ebms:meetings/convert-codes-to-types`, { codes: JSON.stringify(codes) });
      return resp!.data.meeting_types;
    } catch (e) {
      this.notifStore.error("Failed to convert meeting types");
      this.codesSearchStatus = ReqStatus.Failed;
      return null;
    }
  };

  validateMeetingCode = async (code: string, meetingType: string) => {
    try {
      const resp = await api.post("ebms:meetings/validate-code", {
        meeting_type: meetingType,
        code,
      });
      return resp!.data;
    } catch (e) {
      this.notifStore.error("Failed to validate meeting code");
      throw e;
    }
  };

  searchMeetingCodes = async (search: string) => {
    runInAction(() => {
      this.codesSearchStatus = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get(`ebms:meetings/search-code/?term=${search}&`);
      const results = resp!.data.results;
      runInAction(() => {
        this.meetingCodesSearch = {
          search,
          results,
        };
      });
      return resp.data;
    } catch (e) {
      this.notifStore.error("Failed to load meetings");
      this.codesSearchStatus = ReqStatus.Failed;
      return null;
    }
  };

  loadSingleMeeting = async (id: string | number): Promise<MeetingNode> => {
    try {
      runInAction(() => {
        this.status = ReqStatus.InProcess;
      });
      const resp = await api.get(`ebms:meetings/${id}`);
      runInAction(() => {
        this.status = ReqStatus.Success;
      });
      return resp!.data;
    } catch (e) {
      runInAction(() => {
        this.status = ReqStatus.Failed;
      });
      return null;
    }
  };

  loadMeetings = async (search: string, cursor?: string): Promise<PaginatedResult<MeetingNode>> => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      let resp;
      if (!cursor) {
        let payload = this.meetingsQueryParams || {};
        const payloadKeys = Object.keys(payload);
        const payloadHasOnlyOrdering = payloadKeys.length === 1 && payloadKeys[0] === "ordering";
        if (payloadKeys.length === 0 || payloadHasOnlyOrdering) {
          payload = { ...payload, future: "true" };
        }
        resp = await api.get(`ebms:meetings`, {
          page_size: 10,
          ...payload,
        });
      } else {
        resp = await api.getBare(cursor);
      }
      const meetings = resp!.data.results;
      const count = resp!.data.total_items_count;
      runInAction(() => {
        this.meetings = meetings;
        this.status = ReqStatus.Success;
        this.meetingsCount = count;
      });
      return resp.data;
    } catch (err) {
      this.notifStore.error("Failed to load meetings");
      this.status = ReqStatus.Failed;
      return null;
    }
  };

  postMeeting = async (meeting: MeetingNode): Promise<any> => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.post("ebms:meetings", meeting);
      return resp!.data;
    } catch (e) {
      this.notifStore.error("Failed to create meeting");
      this.status = ReqStatus.Failed;
      throw e;
    }
  };

  patchMeeting = async (meeting: MeetingNode, id: number): Promise<any> => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.patch(`ebms:meetings/${id}`, meeting);
      console.log("resp ===", resp.status);
      if (resp.status === 400) {
        throw { response: resp };
      }
      return resp!.data;
    } catch (e) {
      this.notifStore.error("Failed to update meeting");
      this.status = ReqStatus.Failed;
      throw e;
    }
  };

  cloneMeeting = async (meeting: MeetingNode, id: number): Promise<MeetingNode | MeetingsError> => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.post(`ebms:meetings/${id}/clone`, meeting);
      return resp!.data;
    } catch (e) {
      this.notifStore.error("Failed to clone meeting");
      this.status = ReqStatus.Failed;
      throw e;
    }
  };
}

const meetingStore = new MeetingStore(notificationStore);

export default meetingStore;
