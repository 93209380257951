import { Button, InlineLoading } from "@wfp/ui";
import { Document } from "~/document";
import cn from "classnames";
import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import bookmarkStore from "~/stores/bookmarks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ReqStatus } from "~/util";

const DocBookmarkButton = observer((props: { doc: Document; className?: string }) => {
  const { doc } = props;
  const storeState = bookmarkStore.isBookmarked(doc.id);
  const loading = bookmarkStore.toggleReqStatus === ReqStatus.InProcess;
  const [bookmarked, setBookmarked] = useState(storeState);

  useEffect(() => {
    setBookmarked(storeState);
  }, [storeState]);

  const title = bookmarked ? "Remove bookmark" : "Add bookmark";

  const toggle = async () => {
    const newState = !bookmarked;
    await bookmarkStore.toggleBookmarkedStatus(doc.id, doc.title, newState);
    setBookmarked(newState);
  };

  const icon = bookmarked ? solid("star") : regular("star");

  return (
    <div className="df">
      <Button
        small
        kind={bookmarked ? "primary" : "secondary"}
        area-label={title}
        title={title}
        disabled={loading}
        onClick={toggle}
        data-testid="bookmark-btn"
        className={cn(
          styles.button,
          {
            [styles.active]: bookmarked,
          },
          props.className
        )}
      >
        <span>{bookmarked ? "Bookmarked" : "Add bookmark"}</span>
        <FontAwesomeIcon icon={icon} />
      </Button>
      {loading && <InlineLoading className="ml-4" />}
    </div>
  );
});

export default DocBookmarkButton;
