import { useRef, useLayoutEffect, useCallback, useState, useEffect, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { MeetingFormKey, MeetingLanguage } from "~/stores/meeting";
import axios from "axios";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";
import { TopicId } from "~/document";
import { TopicNode } from "~/stores/topics";

// Parse current URL search query into a Record<string, string>
export function useQuery() {
  const { search } = useLocation();
  const query = useMemo(() => {
    const p: any = new URLSearchParams(search);
    const resMap: Record<string, string> = {};
    for (const [key, value] of p.entries()) {
      resMap[key] = value;
    }
    return resMap;
  }, [search]);
  return query;
}

export function useSkipInitialRender(callback: () => any, deps: any[]) {
  const initialRender = useRef<boolean>();

  useEffect(() => {
    if (initialRender.current) callback();
    else {
      initialRender.current = true;
    }
  }, deps);
}

export function useQueryURLSearchParams() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return query;
}

export function useDefaultCollapsedTopics(selected: Set<TopicId>, topics: TopicNode[]) {
  return useMemo(() => {
    const collapsed: TopicId[] = [];
    const processTopicArray = (arr: TopicNode[], parents: TopicId[]) => {
      arr.forEach((topic) => {
        if (selected.has(topic.tid)) {
          collapsed.push(...parents);
        }
        processTopicArray(topic.children, [...parents, topic.tid]);
      });
    };
    topics.forEach((topic) => processTopicArray(topic.children, [topic.tid]));
    return new Set(collapsed);
  }, [topics]);
}

// Track outside/inside click for a particular element
export function usePopupFocusManager() {
  const [focused, setFocused] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const onOutsideClick = useCallback((e: any) => {
    if (ref.current === null) return;
    // if clicked outside, remove focus
    if (!ref.current.contains(e.target)) {
      setFocused(false);
    }
  }, []);
  useLayoutEffect(() => {
    if (focused) {
      document.body.addEventListener("click", onOutsideClick);
    } else {
      document.body.removeEventListener("click", onOutsideClick);
    }
    return () => {
      document.body.removeEventListener("click", onOutsideClick);
    };
  }, [focused]);
  return { focused, setFocused, ref };
}

export function useDebounce(cb: (...args: any[]) => void, delay: number) {
  const options = {
    leading: false,
    trailing: true,
  };
  const inputsRef = useRef<any>(cb);
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  return useCallback(
    debounce(
      (...args) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted()) inputsRef.current.cb(...args);
      },
      delay,
      options
    ),
    [delay, debounce]
  );
}

export function useIsMounted() {
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return () => isMountedRef.current;
}

// Get the current location hash
export function useHash() {
  return document.location.hash;
}

// Link a boolean variable to URL hash parameter, specified by $key
export function useBooleanHash(key: string) {
  const hash = useHash();
  const [value, _setValue] = useState<boolean>(false);
  const navigate = useNavigate();
  const setValue = (val: boolean) => {
    if (val && window.location.hash !== `#${key}`) {
      window.location.hash = `#${key}`;
    } else {
      // remove hash from url
      navigate(window.location.pathname + window.location.search);
    }
  };
  useEffect(() => {
    if (hash === `#${key}`) {
      _setValue(true);
    } else {
      _setValue(false);
    }
  }, [hash]);
  return {
    value,
    setValue,
  };
}

// This links the URL hash to a set of possible values specified by the `keys` argument. Monitors the current value.
export function useMultiBooleanHash(keys: Set<string>) {
  const hash = useHash();
  const [value, _setValue] = useState<string | null>(null);
  const navigate = useNavigate();

  const setValue = (val: string) => {
    if (keys.has(val)) {
      window.location.hash = `#${val}`;
    } else {
      console.error(`Invalid key specified: ${val}`);
    }
  };

  useEffect(() => {
    const k = window.location.hash.substr(1, window.location.hash.length - 1);
    if (keys.has(k)) {
      _setValue(k);
    } else {
      _setValue(null);
    }
  }, [hash]);

  return {
    value,
    setValue,
  };
}

// JS access to current device screen parameters
export function useLayoutWidth() {
  const [width, setWidth] = useState<number>(process.env.JEST ? 10000 : window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return width;
}
export const enum LayoutMode {
  XS = 0,
  SM = 1,
  MD = 3,
  LG = 2,
  XL = 4,
}
export function useLayoutMode() {
  const width = useLayoutWidth();
  if (width <= 500) {
    return LayoutMode.XS;
  } else if (width <= 768) {
    return LayoutMode.SM;
  } else if (width <= 992) {
    return LayoutMode.MD;
  } else if (width <= 1200) {
    return LayoutMode.LG;
  } else {
    return LayoutMode.XL;
  }
}

export const useDropdown = (defaultValue?: boolean) => {
  const [isShown, setShown] = useState<boolean>(defaultValue);
  const toggle = () => setShown((prev) => !prev);
  return {
    isShown,
    toggle,
  };
};

export type ModalController = {
  open: () => void;
  close: () => void;
  shown: boolean;
};
export const useModal = (): ModalController => {
  const [shown, setShown] = useState<boolean>(false);
  const open = () => setShown(true);
  const close = () => setShown(false);
  const controller = { open, close, shown };
  return controller;
};

export const useLocalizedFieldsGetter = (lang: MeetingLanguage) => {
  return useCallback((prefix) => `${prefix}_${lang}` as MeetingFormKey, [lang]);
};
