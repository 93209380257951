import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Page, { PageBreadcrumbs } from "~/components/Page";
import { Text, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button } from "@wfp/ui";
import styles from "./styles.module.scss";
import paths from "~/paths";
import { Container, Row, Col } from "react-grid-system";
import docStore from "~/stores/document";
import {
  Document,
  DocPayload,
  DocPayloadForEdit,
  APIDocPayload,
  EditableDocPayload,
  DocPayloadForUpload,
  requiredFieldsForUpload,
  isDocFieldSingle,
  docInstanceLabel,
} from "~/document";
import { useMatch, useNavigate, useParams } from "react-router";
import FileDropArea, { MAX_FILENAME_LENGTH } from "./FileUploadArea";
import ConfirmationModal from "./ConfirmationModal";
import DocumentForm, { SingleFormProps, SingleFormValue, SingleOnChangeKeys } from "~/components/DocumentForm";
import topicStore from "~/stores/topics";
import RelatedDocumentsSelect from "./RelatedDocumentsSelect";
import tagStore from "~/stores/tags";
import DocumentLangVersionSelect from "./DocumentLangVersionSelect";
import { reverseMap, assert, dateToAPIString } from "~/util";
import { useBooleanHash, useModal } from "~/util/hooks";
import cn from "classnames";
import templateStore from "~/stores/templates";
import { iconHelp } from "@wfp/icons";
import { ValidationSchema, field, validate } from "~/validation";
import { VErrors } from "~/validation";
import { toJS } from "mobx";
import { APPLICATION_TOKEN, ReqStatus } from "~/api";
import { observer } from "mobx-react";
import HelpModal from "./HelpModal";
import FullPageLoader from "~/components/FullPageLoader";
import WithAutoScrollingWrapper from "~/components/WithAutoScrollingWrapper";
import moment from "moment";
import meetingStore from "~/stores/meeting";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";

// secondary and primary type
const DOC_TYPES_TO_SELECT_FOR_DOCUMENT_CREATION = 2;

type MappedKey = keyof EditableDocPayload | (keyof EditableDocPayload)[] | null;

type AllKeys = keyof SingleFormValue;
type TT = { [P in AllKeys]: MappedKey };
const formToDPMapping: TT = {
  docTypes: "types",
  topics: "topics",
  owners: "owner",
  series: "serie",
  divisions: "division",
  offices: "office",
  languages: "language",
  access: "access",
  protocol: "protocol",
  expiration_date: "expiration_date",
  release_date: "release_date",
  publication_date: "publication_date",
  tags: "tags",
  title: "title",
  author: "author",
  countries: "countries",
  projects: "projects",
  abstract: "abstract",
  comment: "comment",
  pdf_option: "pdf_option",
  number: "number",
  meeting_codes: "meeting_codes",
  custom: null,
  instance: "instance",
};

const dpToFormMapping: Map<keyof EditableDocPayload, keyof SingleFormValue> = reverseMap(formToDPMapping);

// Convert form change into the document payload format
export const docPayloadFromFormChange = (
  docPayload: EditableDocPayload,
  key: SingleOnChangeKeys,
  value: Set<number | string> | Array<string | number> | string | number
): EditableDocPayload => {
  const np: EditableDocPayload = { ...docPayload };
  if (!docPayload.types?.includes(20)) {
    np.instance = undefined;
  }
  if (key.includes("custom/")) {
    return { ...np, custom: { ...np.custom, [key.replace("custom/", "")]: value } };
  }
  const mk = formToDPMapping[key];
  const mappedKey: MappedKey = mk;

  if (mappedKey === null) return np;
  if (mappedKey instanceof Array) {
    assert(value instanceof Array || value instanceof Set);
    let valArr = Array.from(value);
    for (let i = 0; i < Math.min(valArr.length, mappedKey.length); ++i) {
      const k: keyof EditableDocPayload = mappedKey[i];
      (np[k] as EditableDocPayload[typeof k]) = valArr[i];
    }
  } else {
    let val = value instanceof Set ? Array.from(value) : value;
    const isSingle = isDocFieldSingle(mappedKey);

    if (isSingle) {
      (np[mappedKey] as EditableDocPayload[typeof mappedKey]) = val instanceof Array ? val[0] : val;
    } else {
      (np[mappedKey] as EditableDocPayload[typeof mappedKey]) = val;
    }
  }
  return np;
};

export const splitMeetingCodes = (docPayloadCodes?: { [key: string]: string[] }) => {
  if (!docPayloadCodes) {
    return [];
  }
  const codes: string[] = [];
  Object.entries(docPayloadCodes).forEach(([key, value]) => {
    value.forEach((code) => codes.push(`${key}/${code}`));
  });
  return codes;
};

const checkUploadFields = (dp: APIDocPayload | EditableDocPayload): dp is DocPayloadForUpload => {
  for (let field of requiredFieldsForUpload) {
    if (!(field in dp)) return false;
  }
  return true;
};

const dpKeyToFormPropKey = (key: keyof EditableDocPayload): keyof SingleFormValue => {
  return dpToFormMapping.get(key)!;
};

const useFormPropsFromState = (
  docPayload: EditableDocPayload,
  validationErrors: VErrors<UploadFormState>,
  editing: boolean
) => {
  const defaultProps = {
    title: {},
    publication_date: {
      disabled: editing,
    },
    release_date: {
      disabled: editing,
    },
    expiration_date: {
      startDate: docPayload.date_added ? new Date(docPayload.date_added) : undefined,
    },
    protocol: {
      disabled: editing,
    },
  };
  const [props, setProps] = useState<SingleFormProps>(defaultProps);
  const [touched, setTouched] = useState<SingleFormProps>({});
  const errors = useMemo(() => validationErrors.get("docPayload"), [validationErrors]);

  const onBlur = (key: string) => setTouched((prev) => ({ ...prev, [key]: true }));

  useEffect(() => {
    if (!errors) return;
    const updatedProps = { ...defaultProps };
    for (const [key, err] of errors.entries()) {
      const kkey = key as keyof EditableDocPayload;
      const fkey = dpKeyToFormPropKey(kkey);
      if (!(key in updatedProps)) {
        updatedProps[fkey] = {};
      }
      // skip disabled fields
      if (updatedProps[fkey].disabled) continue;
      const msg = (err as any).join(", ");
      updatedProps[fkey].error = true;
      updatedProps[fkey].invalid = !!touched[fkey];
      updatedProps[fkey].invalidText = msg;
      updatedProps[fkey].onBlur = () => onBlur(fkey);
    }
    setProps(updatedProps);
  }, [errors, touched, docPayload, editing]);

  return errors ? props : defaultProps;
};

interface UploadFormState {
  docPayload: EditableDocPayload;
  file: File | null;
}

const uploadSchema: ValidationSchema<UploadFormState> = {
  docPayload: {
    title: field("title").string().required(),
    topics: field("topics").array().required("You need to select at least one topic"),
    author: field("author").string().required(),
    publication_date: field("publication_date").date().required().onlyFuture(),
    // release_date: field("release_date").date().required(),
    expiration_date: field("expiration_date").date().required(),
    types: field("types").array().required("You need to specify at least one document type"),
    access: field("access").string().required("You need to specify the access level"),
    language: field("language").string().required(),
    office: field("office").string().required(),
  },
  file: field("file").file().required("Select a file"),
};

const editSchema = {
  ...uploadSchema,
  docPayload: {
    ...uploadSchema.docPayload,
    publication_date: field("publication_date").date(),
    release_date: field("release_date").date(),
    expiration_date: field("expiration_date").date(),
  },
};

export const formValueFromState = (docPayload: EditableDocPayload): SingleFormValue => {
  const d = docPayload;
  const res = {
    ...docPayload,
    topics: new Set(docPayload.topics),
    tags: docPayload.tags ?? [],
    countries: docPayload.countries ?? [],
    docTypes: new Set(docPayload.types),
    projects: d.projects ?? [],
    languages: d.language !== null && d.language !== undefined ? [d.language] : [],
    access: d.access !== null && d.access !== undefined ? [d.access] : [],
    series: d.serie !== null && d.serie !== undefined ? [d.serie] : [],
    owners: d.owner !== null && d.owner !== undefined ? [d.owner] : [],
    offices: d.office !== null && d.office !== undefined ? [d.office] : [],
    divisions: d.division !== null && d.division !== undefined ? [d.division] : [],
    expiration_date:
      d.expiration_date === null ? null : d.expiration_date !== undefined ? new Date(d.expiration_date) : undefined,
    publication_date: d.publication_date ? new Date(d.publication_date) : undefined,
    release_date: d.release_date ? new Date(d.release_date) : undefined,
    instances: [],
    meeting_codes: d.meeting_codes || [],
    custom: d.custom,
  };
  return res;
};

// only true when navigated to /edit/${docProto}
export const isEditing = (): [string | undefined, boolean] => {
  const params = useParams();
  return [params.id, params.id !== undefined];
};

const compareDocPayloads = (originalDocument: EditableDocPayload, docPayload: EditableDocPayload) => {
  return Object.fromEntries(
    Object.entries(docPayload).filter(([key, value]) => {
      type K = keyof typeof originalDocument;
      const kkey = key as K;
      const origValue = originalDocument[kkey] as any;

      if (value instanceof Date) {
        return new Date(origValue).getTime() !== value.getTime();
      }
      return originalDocument[kkey] !== value;
    })
  );
};

const docPayloadFromEditable = (edp: EditableDocPayload): DocPayload => {
  return edp;
};

const defaultDocPayload = {
  publication_date: moment().toISOString(),
};

export const GOVERNANCE_BODY_TYPE_ID = 20;

const UploadPage = observer(() => {
  const [editedId, editing] = isEditing();
  const navigate = useNavigate();

  // Original state before edit
  // If editing, these are null
  const [originalDocument, setOriginalDocument] = useState<Document | null>(null);
  const [originalFile, setOriginalFile] = useState<File | null>(null);

  // Edit state
  const [docPayload, setDocPayload] = useState<EditableDocPayload>(defaultDocPayload);
  const [file, setFile] = useState<File | null>(null);

  const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);

  const clear = () => {
    setOriginalDocument(null);
    setOriginalFile(null);
    setDocPayload(defaultDocPayload);
    setFile(null);
  };

  // Clear when mode changes
  useEffect(() => {
    clear();
  }, [editing]);

  const fileHasChanged = originalFile !== file;
  const filenameValid = (file?.name || "").length <= MAX_FILENAME_LENGTH;

  const onlyUpdatedFields: EditableDocPayload = useMemo(() => {
    if (!editing || originalDocument === null) return {};
    return compareDocPayloads(originalDocument, docPayload);
  }, [docPayload, originalDocument, editing]);
  const hasAnythingChanged = editing ? Object.keys(onlyUpdatedFields).length !== 0 || fileHasChanged : true;

  const prepareForUpload = async (payload: EditableDocPayload): Promise<APIDocPayload> => {
    const { meeting_codes, instance, types, type, secondary_type, ...dp } = payload;
    const custom = dp.custom || {};
    if (meeting_codes) {
      if (meeting_codes.length) {
        custom.meeting_codes = {};
        meeting_codes.forEach((code) => {
          const [key, value] = code.split("/");
          if (custom.meeting_codes[key]) {
            custom.meeting_codes[key].push(value);
          } else {
            custom.meeting_codes[key] = [value];
          }
        });
        const meetingTypes = await meetingStore.convertMeetingCodesToTypes(meeting_codes);
        if (meetingTypes) {
          custom.meeting_types = meetingTypes;
        }
      } else {
        custom.meeting_codes = {};
        custom.meeting_types = [];
      }
    }

    custom.document_instance =
      instance !== undefined
        ? {
            label: docInstanceLabel[instance].toLowerCase(),
            value: instance,
          }
        : {};

    let res: APIDocPayload = {
      ...dp,
      expiration_date: dp.expiration_date !== undefined ? dateToAPIString(dp.expiration_date) : undefined,
      publication_date: dp.publication_date !== undefined ? dateToAPIString(dp.publication_date) : undefined,
      release_date: dp.release_date !== undefined ? dateToAPIString(dp.release_date) : undefined,
    };
    if (types) {
      res.type = types?.[0] || null;
      res.secondary_type = types?.[1] || null;
    }

    if (Object.keys(custom).length) {
      res.custom = custom;
      res.custom_application = "ebmeetingdocs";
      res.custom_token = APPLICATION_TOKEN;
    }
    return res;
  };

  const onApiError = () => {
    setNotificationData({
      title: "Document was not uploaded due to some API error",
      kind: "error",
    });
    showNotificationWithTimeout();
  };

  const onFieldsMissing = () => {
    setNotificationData({
      title: "Document was not uploaded, some required fields are missing",
      kind: "error",
    });
    showNotificationWithTimeout();
  };

  const handleFileError = (errorMessage) => {
    setNotificationData({
      title: errorMessage,
      kind: "error",
    });
    showNotificationWithTimeout();
  };

  const handleFilenameValidation = () => {
    if (!filenameValid) {
      setNotificationData({
        title: "File was not uploaded, filename is longer than 100 symbols.",
        kind: "error",
      });
      showNotificationWithTimeout();
    }
  };

  const upload = async () => {
    handleFilenameValidation();
    if (editing) {
      const payload: DocPayloadForEdit = await prepareForUpload({
        ...onlyUpdatedFields,
        instance: docPayload.instance,
        types: docPayload.types,
      });
      const doc = await docStore.edit(
        originalDocument!.protocol,
        payload,
        fileHasChanged && filenameValid ? file : null,
        handleFileError
      );
      if (doc === null) {
        const withAllFields = checkUploadFields(payload);
        if (!withAllFields) {
          onFieldsMissing();
          return;
        }
        onApiError();
        return;
      }
      navigate(paths.construct.docDetail(originalDocument!.protocol));
    } else {
      const payload = await prepareForUpload(docPayload);
      const withAllFields = checkUploadFields(payload) || process.env.JEST === "1";
      if (withAllFields) {
        let uploadSuccess = true;
        const createdDoc = await docStore.upload(payload, filenameValid ? file! : null, (err) => {
          handleFileError(err);
          uploadSuccess = false;
        });
        if (createdDoc === null) {
          onApiError();
          return;
        }
        if (uploadSuccess) {
          setNotificationData({
            title: "Document was uploaded successfully",
            kind: "success",
          });
        }

        navigate(paths.construct.docDetail(createdDoc.protocol));
      } else {
        onFieldsMissing();
        console.warn("Some upload fields are missing");
      }
    }
  };

  const [confirmationPayload, setConfirmationPayload] = useState<DocPayload | null>(null);
  const showConfirmationModal = confirmationPayload !== null;
  const startUpload = () => {
    setConfirmationPayload(docPayloadFromEditable(docPayload));
  };
  const updateState = (doc: Partial<Document>) => {
    const np: EditableDocPayload = doc;
    np.custom = doc.metadata?.ebmeetingdocs || {};
    setDocPayload(np);
  };

  // If editing
  const storeDoc = editedId !== undefined ? docStore.getDocByProto(editedId) : null;
  useEffect(() => {
    if (storeDoc !== null) {
      updateState(storeDoc);
    }
  }, [storeDoc]);

  const [loadingEditedDocument, setLoadingEditedDocument] = useState<boolean>(false);

  const loadDocToEdit = async (proto: string) => {
    setLoadingEditedDocument(true);
    const doc = await docStore.loadDocument(proto);
    if (doc === null) return;
    setOriginalDocument(doc!);
    doc.meeting_codes = splitMeetingCodes(doc.metadata?.ebmeetingdocs?.meeting_codes);
    doc.instance = doc.metadata?.ebmeetingdocs?.document_instance?.value;
    doc.types = [];
    if (doc.type) doc.types.push(doc.type);
    if (doc.secondary_type) doc.types.push(doc.secondary_type);
    const docVersions = await docStore.loadDocumentVersions(doc.id);
    if (docVersions.length !== 0) {
      const lastVersion = docVersions[docVersions.length - 1];
      let f = new File([], doc.file_info?.name || lastVersion?.uuid);
      setFile(f);
      setOriginalFile(f);
    }
    updateState(doc);
    setLoadingEditedDocument(false);
  };

  const updateExactDocFields = useCallback(async (proto: string, keysToEdit: (keyof Document)[]) => {
    setLoadingEditedDocument(true);
    const doc = await docStore.loadDocument(proto);
    if (doc) {
      const updatedFields = {};
      keysToEdit.forEach((key) => (updatedFields[key] = doc[key]));
      setOriginalDocument((prev) => ({ ...prev, ...updatedFields }));
    }
    setLoadingEditedDocument(false);
  }, []);

  useEffect(() => {
    if (editedId === undefined) return;
    loadDocToEdit(editedId);
    topicStore.loadTopics();
    tagStore.f.load("", null);
  }, [editedId]);

  const onDocChange = (key: SingleOnChangeKeys, value: any) => {
    setDocPayload((prev) => {
      return docPayloadFromFormChange(prev, key as keyof SingleFormValue, value);
    });
  };

  // Document payload -> form-compatible format
  const formValue: SingleFormValue = useMemo(() => formValueFromState(docPayload), [docPayload]);

  const typesIncludesGovernanceBody = useMemo(
    () => docPayload.types?.includes(GOVERNANCE_BODY_TYPE_ID) || false,
    [docPayload.types]
  );
  const schema = useMemo(() => {
    const validationSchema = editing ? editSchema : uploadSchema;
    if (typesIncludesGovernanceBody) {
      validationSchema.docPayload["instance"] = field("instance").string().required();
    } else {
      delete validationSchema.docPayload["instance"];
    }
    return validationSchema;
  }, [typesIncludesGovernanceBody, editing]);

  const state = useMemo(() => ({ file: file, docPayload }), [file, docPayload]);
  const validationErrors = useMemo(() => {
    if (editing && loadingEditedDocument) {
      return new Map();
    }
    return validate(schema, state);
  }, [schema, state]);
  const valid = validationErrors.size === 0;
  const areThereValidationErrors = !valid;
  const canUpload = valid && hasAnythingChanged;

  const formProps = useFormPropsFromState(docPayload, validationErrors, editing);

  const langVerModal = useBooleanHash("translations");
  const relModal = useBooleanHash("relations");

  // Upload with a template specified in the path
  const match = useMatch(paths.match.uploadDocWithTemplate);
  const templateId = match?.params.templateId;
  useEffect(() => {
    (async () => {
      if (templateId === undefined) return;
      const template = await templateStore.getTemplate(templateId);
      if (template === null) return;
      updateState(template.json_template);
    })();
  }, [templateId]);

  // UploadSimilar (upload similar to a specified document)
  const matchSimilarUpload = useMatch(paths.match.uploadSimilar);
  const similarDocId = matchSimilarUpload?.params.documentId;
  const uploadingSimilar = similarDocId ?? false;
  useEffect(() => {
    (async () => {
      if (similarDocId === undefined) return;
      setLoadingEditedDocument(true);
      const similarDoc: DocPayload | null = toJS(await docStore.loadDocument(similarDocId));
      if (similarDoc === null) return;
      delete similarDoc["title"];
      delete similarDoc["id"];
      delete similarDoc["protocol"];
      delete similarDoc["uuid"];
      delete similarDoc["translations"];
      delete similarDoc["trans_group"];
      delete similarDoc["owner_display"];
      delete similarDoc["owner"];
      delete similarDoc["versions"];
      delete similarDoc["object_permission"];
      delete similarDoc["file_info"];
      delete similarDoc["trans_group_id"];
      delete similarDoc["uploader"];
      delete similarDoc["comment"];
      delete similarDoc["last_modified_date"];
      delete similarDoc["date_added"];
      delete similarDoc["date_display"];
      delete similarDoc.metadata?.ebmeetingdocs?.["comment"];
      delete similarDoc.metadata?.ebmeetingdocs?.["disclaimer"];
      delete similarDoc.metadata?.ebmeetingdocs?.["decision"];
      delete similarDoc.metadata?.ebmeetingdocs?.["note"];
      delete similarDoc.metadata?.ebmeetingdocs?.["summary"];
      const isReleaseDatePast = moment(similarDoc["release_date"]).isBefore(moment(new Date()));
      const types = [];
      if (similarDoc.type) {
        types.push(similarDoc.type);
      }
      if (similarDoc.secondary_type) {
        types.push(similarDoc.secondary_type);
      }
      updateState({
        ...similarDoc,
        types,
        instance: similarDoc.metadata?.ebmeetingdocs?.document_instance?.value,
        release_date:
          isReleaseDatePast || !similarDoc["release_date"]
            ? moment(new Date()).toISOString()
            : similarDoc["release_date"],
        meeting_codes: splitMeetingCodes(similarDoc.metadata?.ebmeetingdocs?.meeting_codes),
      });
      setLoadingEditedDocument(false);
    })();
  }, [similarDocId]);

  const helpModal = useModal();
  useEffect(() => {
    if (validationErrors.size === 0) helpModal.close();
  }, [validationErrors]);

  const loading = loadingEditedDocument;

  useEffect(() => {
    if (!typesIncludesGovernanceBody) {
      setDocPayload((prev) => ({ ...prev, instance: undefined }));
    }
  }, [typesIncludesGovernanceBody]);

  useEffect(() => {
    if (!docPayload.types?.length) {
      setDocPayload((prev) => ({ ...prev, types: [20] }));
    }
  }, []);

  return (
    <WithAutoScrollingWrapper>
      <Page title="Upload document" className="upload-document">
        <FullPageLoader loading={loading}>
          <HelpModal errors={validationErrors} controller={helpModal} />
          <PageBreadcrumbs>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/#">
                  <BreadcrumbHome />
                </a>
              </BreadcrumbItem>
              {editing ? (
                <>
                  {originalDocument && (
                    <BreadcrumbItem>
                      <a data-testid="document-title" href={paths.construct.docDetail(originalDocument.protocol)}>
                        Document {originalDocument?.protocol}
                      </a>
                    </BreadcrumbItem>
                  )}
                  <BreadcrumbItem disableLink>Edit</BreadcrumbItem>
                </>
              ) : uploadingSimilar ? (
                <BreadcrumbItem disableLink>
                  <span>Upload document</span> (using <i className="text-italic">{similarDocId}</i>)
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem disableLink>Upload documents</BreadcrumbItem>
              )}
            </Breadcrumb>
          </PageBreadcrumbs>

          <ConfirmationModal
            open={showConfirmationModal}
            docPayload={docPayload}
            submitting={
              editing ? docStore.editStatus === ReqStatus.InProcess : docStore.uploadStatus === ReqStatus.InProcess
            }
            filename={file?.name}
            editing={editing}
            onAgree={async () => {
              await upload();
              setConfirmationPayload(null);
            }}
            close={() => setConfirmationPayload(null)}
          />

          <Container
            style={{ paddingLeft: 0, paddingRight: 0, margin: 0 }}
            className={cn([styles.uploadForm, "h-100 w-100"])}
            fluid
          >
            <Row className="h-100" style={{ marginRight: 0, marginLeft: 0 }}>
              <Col xs={12} lg={12} xl={4} xxl={3} className={styles.leftCol}>
                <Container
                  style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 }}
                  className="w-100"
                  fluid
                >
                  <Row className="h-100">
                    <Col xs={12} md={12} xl={12}>
                      <FileDropArea
                        filenameValid={filenameValid}
                        value={file !== null ? [file] : []}
                        onAttachFile={(files) => setFile(files[0])}
                      />
                    </Col>
                    <Col xs={12} md={12} xl={12}>
                      {validationErrors.get("file") !== undefined && (
                        <Text className="error-text">{(validationErrors.get("file")! as any).join(", ")}</Text>
                      )}
                      {editing && (
                        <div className="mt-12">
                          <div className={styles.leftColSection}>
                            <RelatedDocumentsSelect
                              docs={docPayload!.related! ?? []}
                              onAdd={docStore.addRelatedDoc}
                              doc={docPayload as any as Document}
                              showModal={relModal.value}
                              setShowModal={relModal.setValue}
                              submitting={docStore.addRelatedDocStatus === ReqStatus.InProcess}
                            />
                          </div>
                          <div className={styles.leftColSection}>
                            <DocumentLangVersionSelect
                              doc={docPayload as any as Document}
                              showModal={langVerModal.value}
                              setShowModal={langVerModal.setValue}
                              updateExactDocFields={updateExactDocFields}
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col xs={12} md={12} lg={12} xl={8} xxl={6} className={styles.formContainer}>
                <div className="mb-24">
                  <DocumentForm
                    value={formValue}
                    onChange={onDocChange}
                    typesIncludesGovernanceBody={typesIncludesGovernanceBody}
                    multipleDocs={false}
                    formProps={formProps}
                    docTypeSelectProps={{
                      maxOptionsToSelect: DOC_TYPES_TO_SELECT_FOR_DOCUMENT_CREATION,
                    }}
                  />
                </div>
                <div className="space-x-16">
                  {process.env.JEST && <div data-testid="upload-document" onClick={upload} />}
                  <Button
                    disabled={!canUpload}
                    title={!canUpload ? "You need to correct all errors to submit the form" : undefined}
                    onClick={startUpload}
                    kind="primary"
                  >
                    {editing ? "Save document" : "Upload document"}
                  </Button>
                  <Button
                    disabled={!areThereValidationErrors}
                    title={!canUpload ? "Show the reasons why you can't submit your form" : undefined}
                    onClick={helpModal.open}
                    icon={iconHelp}
                    kind="ghost"
                  >
                    Why can't I submit?
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </FullPageLoader>
      </Page>
    </WithAutoScrollingWrapper>
  );
});

export default UploadPage;
