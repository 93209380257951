import { useEffect, useMemo, useState } from "react";
import { Text, Tag as WFPUITag, Link } from "@wfp/ui";
import { Row, Col } from "react-grid-system";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import paths from "~/paths";
import SearchQuery, { SearchQueryJSON } from "~/searchQuery";
import { iconClose } from "@wfp/icons";
import IconButton from "../IconButton";
import { observer } from "mobx-react";
import isSet from "lodash/isSet";
import { useQueryURLSearchParams } from "~/util/hooks";

const Tag = (props: { remove: () => void; text: string; title: string; canRemove: boolean }) => (
  <WFPUITag className={styles.summaryItemTag} type="info" title={props.title}>
    <Text>{props.text}</Text>
    {props.canRemove && (
      <IconButton
        data-testid={`${props.title}-filter`}
        icon={iconClose}
        onClick={props.remove}
        description="Remove this filter"
      />
    )}
  </WFPUITag>
);

interface AFDProps {
  onSearch: (sq: SearchQuery) => void;
  searchQuery: SearchQuery | null;
}

const AdvancedFiltersDisplay = observer((props: AFDProps) => {
  const { onSearch, searchQuery } = props;

  const n = searchQuery ? searchQuery.numOfAppliedSearchFilters() : 0;
  const isThereAnyAdvancedFiltersApplied = n !== 0;

  const navigate = useNavigate();
  const goToAdvancedSearch = () => {
    navigate(paths.construct.advancedSearch(searchQuery));
  };
  const pageQuery = useQueryURLSearchParams();
  const [shown, setShown] = useState(true);
  const [queryInJson, setQueryInJson] = useState<SearchQueryJSON | null>(null);
  useEffect(() => {
    (async () => {
      if (searchQuery === null) return;
      // preload all required fields for display
      await searchQuery.requestAllMissingDataForQuery();
      const topics = pageQuery.get("topics");
      searchQuery.topics = new Set(topics ? topics.split(",").map((item) => +item) : []);
      const docTypes = pageQuery.get("docTypes");
      searchQuery.docTypes = new Set(docTypes ? docTypes.split(",").map((item) => +item) : []);
      setQueryInJson(searchQuery.toFullJSON());
    })();
  }, [searchQuery]);

  const filtersRendered = useMemo(() => {
    if (!shown || !queryInJson || !searchQuery) return null;
    const entries = Object.entries(queryInJson).map(([_fieldName, { value, label }], idx: number) => {
      const fieldName: keyof SearchQuery = _fieldName as any;
      const isAdvanced = searchQuery.isAdvancedFilterField(fieldName);
      const canRemove = isAdvanced;
      const title = label;
      if (value instanceof Array) {
        if (value.length === 0) return null;
        return value.map((a, idx) => (
          <Tag
            canRemove={canRemove}
            remove={() => onSearch(searchQuery.remove(fieldName, { idx }))}
            text={a}
            key={idx}
            title={title}
          />
        ));
      }

      if (isSet(value)) {
        if (value.size === 0) return null;
        return Array.from(value).map((a: string) => {
          return (
            <Tag
              canRemove={canRemove}
              remove={() => onSearch(searchQuery.remove(fieldName, { key: a }))}
              text={a}
              key={idx}
              title={title}
            />
          );
        });
      }

      if (!value) return null;

      return (
        <Tag
          canRemove={canRemove}
          remove={() => onSearch(searchQuery.remove(fieldName, {}))}
          text={value.toString()}
          key={idx}
          title={title}
        />
      );
    });
    return <div className={styles.advancedSearchFiltersDisplay}>{entries}</div>;
  }, [shown, queryInJson]);

  return (
    <>
      <Row gutterWidth={0} style={{ whiteSpace: "nowrap" }}>
        {isThereAnyAdvancedFiltersApplied ? (
          <div className="df fdc">
            <Link
              href="#"
              className="fs-14 mb-8 no-outline"
              onClick={(e: any) => {
                e.preventDefault();
                setShown(!shown);
              }}
            >
              {shown ? "Hide advanced filters" : `Show search filters (${n} applied)`}
            </Link>
            <Link
              href="#"
              className="fs-14 mb-8 no-outline"
              onClick={(e: any) => {
                e.preventDefault();
                goToAdvancedSearch();
              }}
            >
              Edit advanced search
            </Link>
            {filtersRendered}
          </div>
        ) : (
          <Col xs={12} md={6}>
            <Link
              href="#"
              className="fs-14"
              onClick={(e: any) => {
                e.preventDefault();
                goToAdvancedSearch();
              }}
            >
              Advanced search
            </Link>
          </Col>
        )}
      </Row>
    </>
  );
});

export default AdvancedFiltersDisplay;
